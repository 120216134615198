<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5 px-3">
                    <v-row class="px-3">
                        <v-col cols="0" md="2">
                            <admin-etc-menu></admin-etc-menu>
                        </v-col>
                        <v-col cols="12" md="9">

                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2">
                                    상단사진
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    <span style="font-weight: bold; font-size:0.9rem;">
                                        사진등록 (사진을 변경시 파일을 선택해주세요.)
                                    </span>

                                    <v-btn
                                            color="primary"
                                            class="mx-auto white--text center-block align-center float-right"
                                            small
                                            @click="btnClick"
                                    >
                                        저장
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-row class="my-3">
                                        <v-col
                                                cols="12" md="12"
                                                v-for="(item,i) in imgList"
                                                :key="i"
                                                class="img-card"
                                        >
                                            <v-img
                                                    :src="item.imgRoute"
                                                    class="grey lighten-2"
                                            ></v-img>
                                            <div class="img-card-title">
                                                <input type="file" name="files" accept="image/*" @change="uploadImage($event, item)"/>
                                            </div>
                                            <div class="img-card-title" style="border-top:1px solid #aeaeae;">이름 :
                                                <input v-model="item.imgName"
                                                       style="border: 1px solid silver;width: 30%;padding:0 3px; margin-right:10px;">
                                                위치 :
                                                <input v-model="item.imgType"
                                                       style="border: 1px solid silver;width: 30%;padding:0 3px; margin-right:0px;">

                                            </div>
                                            <!--<v-card>
                                                <v-card-title class="text-h6">

                                                </v-card-title>
                                                <v-card-subtitle>

                                                </v-card-subtitle>
                                            </v-card>-->
                                        </v-col>
                                    </v-row>
                                </v-col>

                            </v-row>

                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AdminEtcMenu from '../../components/AdminEtcMenu'

    export default {
        name: 'AdminImgInfo2',
        components: {
            AdminEtcMenu
        },
        data: () => ({
            title: "",
            imgGubun: "CA",
            imgList:"",

        }),
        created: function () {
            // 맨위로
            if (this.$isMobile()) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            const sch = window.location.search;
            const urlParams = new URLSearchParams(sch);

            //let imgGubun = urlParams.get('imgGubun');
            //let comnOrder = urlParams.get('comnOrder');

            //this.imgGubun = imgGubun;
            this.getData();


        },
        methods: {
            getData() {

                let formdata = {
                    imgGubun: this.imgGubun,
                };

                return this.$store.dispatch("imgInfo/getImgList", formdata)
                    .then((resp) => {

                        this.imgList = resp.message;

                    })
                    .catch((err) => {
                    })

            },
            setTitle(){

                this.imgList.forEach(function (e) {
                    switch (e.imgType) {
                        case "/about/main": {
                            e['imgName'] = "일번지소개";
                           break;
                        }
                        case "/place/main": {
                            e['imgName'] = "시설안내";
                            break;
                        }
                        case "/userGuide/main": {
                            e['imgName'] = "이용안내";
                            break;
                        }
                        case "/reservation": {
                            e['imgName'] = "실시간예약";
                            break;
                        }
                        /*case "/reservation": {
                            e['imgName'] = "예약확인";
                            break;
                        }*/
                        case "/community": {
                            e['imgName'] = "커뮤니티";
                            break;
                        }

                    }

                })

            },

            menuClick(route) {
                location.href = route;
                //this.$router.push({path: route});
            },
            btnClick() {

                let formdata = {
                    imgGubun: this.imgGubun,
                    imgList: this.imgList,
                    lstModPrs: this.$store.state.admin.loginInfo.usrId
                };

                return this.$store.dispatch("imgInfo/updatesImg", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            alert("저장완료");
                            location.reload()

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            uploadImage(e, item) {

                let _this = this;
                let url = '/file/upload.do';

                let formData = new FormData();
                formData.append('file', e.target.files[0]);

                let header = {
                    headers: {'Content-Type': 'multipart/form-data'}
                };

                return axios.post(url, formData, header).then(resp => {

                    if (resp.data) {
                        let imgRoute_tmp = resp.data;

                        this.imgList.forEach(function (e) {

                            if (item.imgType === e.imgType){
                                e['imgRoute'] = imgRoute_tmp;
                            }

                        });


                        let imgInfo = {
                            imgGubun: this.imgGubun,
                            imgType: item.imgType,
                            imgName: item.imgName,
                            imgOrder: "0",
                            imgRoute: imgRoute_tmp,
                            lstModPrs: this.$store.state.admin.loginInfo.usrId,
                            isAdd: true,

                        };

                        this.imgList.push(imgInfo);
                        return resp.data
                    }

                    throw new Error('Server or network error');
                });
            },


        },


    }
</script>

<style>

</style>
